import { useState } from 'react';
import { Card, CardContent, CardHeader, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDashboardForecastQuery, useDashboardInventoryQuery, useMyAccountQuery } from 'src/api';
import { fCurrency } from 'src/utils/formatNumber';
import { DashboardParams } from './Dashboard';
import { RevenueChart } from './RevenueChart';
import { TopCategoryDonut } from './TopCategoryDonut';

const modes = ['Ist', 'Forecast'];

const getTextColor = (value: number) => (value < 0 ? 'error.dark' : 'success.dark');

export function DashboardForecast({ seasonId, leagueId }: DashboardParams) {
  const theme = useTheme();

  const [mode, setMode] = useState(modes[0]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMode(event.target.value);
  };

  const { data } = useDashboardForecastQuery({ variables: { seasonId, leagueId } });
  const { data: accountData } = useMyAccountQuery();
  const { data: inventoryData } = useDashboardInventoryQuery({ variables: { seasonId, leagueId } });

  if ((accountData?.myAccount?.topCategories || []).length > 0 && modes.length == 2)
    modes.push('Oberkategorien');

  if (!data) return null;

  const { planned, actual, actualToPlan, forecast, forecastToPlan, chartSeries } = data.dashboardForecast;

  return (
    <Card variant="outlined" sx={{ height: 550 }}>
      <CardHeader
        title="Erlös &amp; Forecast"
        action={
          <TextField size="small" select label="" value={mode} onChange={handleChange}>
            {modes.map((x, i) => (
              <MenuItem key={i} value={x}>
                {x}
              </MenuItem>
            ))}
          </TextField>
        }
      />
      <CardContent>
        {(mode == modes[0] || mode == modes[1]) && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <div>
                <Typography variant="h6">Plan</Typography>
                <Typography variant="subtitle2">{fCurrency(planned || 0)}</Typography>
              </div>
              {mode == modes[0] && (
                <>
                  <div>
                    <Typography variant="h6">Ist</Typography>
                    <Typography variant="subtitle2">{fCurrency(actual || 0)}</Typography>
                  </div>
                  <div>
                    <Typography variant="h6">zu Plan</Typography>
                    <Typography variant="subtitle2" color={getTextColor(actualToPlan)}>
                      {actualToPlan > 0 && '+'}
                      {fCurrency(actualToPlan || 0)}
                    </Typography>
                  </div>
                </>
              )}
              {mode == modes[1] && (
                <>
                  <div>
                    <Typography variant="h6">Forecast</Typography>
                    <Typography variant="subtitle2">{fCurrency(forecast || 0)}</Typography>
                  </div>
                  <div>
                    <Typography variant="h6">zu Plan</Typography>
                    <Typography variant="subtitle2" color={getTextColor(forecastToPlan)}>
                      {forecastToPlan > 0 && '+'}
                      {fCurrency(forecastToPlan || 0)}
                    </Typography>
                  </div>
                </>
              )}
            </Stack>
            <div style={{ paddingTop: 50 }}>
              <RevenueChart
                chartData={chartSeries}
                chartColors={[
                  theme.palette.primary.dark,
                  theme.palette.primary.main,
                  theme.palette.primary.main,
                ]}
              />
            </div>
          </>
        )}
        {mode == modes[2] && (
          <TopCategoryDonut
            title="Erlös je Oberkategorie"
            sx={{ height: 550 }}
            chartColors={[
              theme.palette.primary.darker,
              theme.palette.primary.main,
              theme.palette.primary.dark,
            ]}
            chartData={inventoryData?.dashboardInventory.topCategoryRevenues || []}
          />
        )}
      </CardContent>
    </Card>
  );
}
