import { merge } from 'lodash-es';
import { useMyAccountQuery, useSeasonQuery } from 'src/api';
import { ReactApexChart, useBaseChartOptions } from 'src/components/chart';
import { fCurrency } from 'src/utils/formatNumber';

const legendMap = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

export interface RevenueChartProps {
  chartColors: string[];
  chartData: { name: string; data: number[][] }[];
}

export function RevenueChart({ chartData, chartColors }: RevenueChartProps) {
  const seasonId = useMyAccountQuery().data?.myAccount.defaultSeason ?? '';

  const { data } = useSeasonQuery({ variables: { id: seasonId }, skip: seasonId == '' });

  const startDate = data?.season.startDate;

  const baseChartOptions = useBaseChartOptions();

  if (!startDate) return null;

  const month = new Date(startDate).getMonth();

  const chartOptions = merge(baseChartOptions, {
    colors: chartColors,
    legend: { horizontalAlign: 'center', position: 'bottom' },
    stroke: {
      width: chartData.length == 3 ? [3, 3, 3] : [3, 3],
      curve: 'straight',
      dashArray: chartData.length == 3 ? [0, 0, 5] : [0, 0],
    },
    yaxis: {
      min: 0,
      labels: {
        show: true,
        formatter: (val: any) => `${(val / 1000000).toFixed(1)} Mio`,
      },
    },
    xaxis: {
      type: 'numeric',
      tickAmount: 'dataPoints',
      min: 0,
      max: 11,
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        formatter: (val: number) => legendMap[(val + month) % 12],
      },
    },
    tooltip: {
      shared: true,
      x: {
        show: false,
      },
      marker: {
        show: true,
      },
      y: {
        formatter: (val: any) => `${fCurrency(val)}`,
      },
    },
  });

  return <ReactApexChart type="line" series={chartData as any} options={chartOptions} height={320} />;
}
