import { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDashboardDiscountQuery, useDashboardRevenueDetailsQuery } from 'src/api';
import { fPercent } from 'src/utils/formatNumber';
import { DashboardParams } from './Dashboard';
import { RevenueDetailsChart } from './RevenueDetailsChart';

const modes = ['V/P', 'C/B'];

export function DashboardDiscount({ seasonId, leagueId }: DashboardParams) {
  const theme = useTheme();

  const [mode, setMode] = useState(modes[1]);

  const { data: discount } = useDashboardDiscountQuery({ variables: { seasonId, leagueId } });
  const { data: revenueDetails } = useDashboardRevenueDetailsQuery({ variables: { seasonId, leagueId } });

  if (!discount || !revenueDetails) return null;

  const data = revenueDetails.dashboardRevenueDetails;

  const series =
    mode == modes[1]
      ? [
          { name: 'Cash', data: [data.totalPrice - data.totalBarter] },
          { name: 'Barter', data: [data.totalBarter] },
        ]
      : [
          { name: 'Verträge', data: [data.contracts] },
          { name: 'Prämien', data: [data.bonus] },
        ];

  return (
    <Stack direction="column" gap={3}>
      <Card variant="outlined">
        <CardHeader title="Rabatte" />
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{fPercent(discount.dashboardDiscount.average)}</Typography>
        </CardContent>
      </Card>
      <Card variant="outlined">
        <CardHeader
          title="Erlöse"
          action={
            <ToggleButtonGroup
              exclusive
              value={mode}
              onChange={(_event, value) => setMode(value)}
              size="small"
            >
              {modes.map((x) => (
                <ToggleButton key={x} value={x}>
                  {x}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          }
        />

        <RevenueDetailsChart
          chartColors={[
            theme.palette.primary.dark,
            theme.palette.primary.lighter,
            theme.palette.primary.main,
          ]}
          series={series}
        />
      </Card>
    </Stack>
  );
}
