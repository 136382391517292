import { ReactNode } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Image } from './Image';

const StyledBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(3, 2),
}));

type EmptyContentProps = {
  title: ReactNode;
  img?: string;
  description?: ReactNode;
} & Omit<BoxProps, 'title'>;

export function EmptyContent({ title, description, img, ...boxProps }: EmptyContentProps) {
  return (
    <StyledBox {...boxProps}>
      <Image
        src={img || '/assets/illustrations/illustration_empty_content.svg'}
        alt="empty content"
        sx={{ height: 200, mb: 3 }}
      />

      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      )}
    </StyledBox>
  );
}
