import { merge } from 'lodash-es';
import { ReactApexChart, useBaseChartOptions } from 'src/components/chart';
import { fCurrency } from 'src/utils/formatNumber';

interface RevenueDetailsChartProps {
  series: any;
  chartColors: string[];
}

export function RevenueDetailsChart(props: RevenueDetailsChartProps) {
  const chartOptions = merge(useBaseChartOptions(), {
    colors: props.chartColors,
    grid: { show: false },
    chart: {
      stacked: true,
    },
    legend: { position: 'bottom', horizontalAlign: 'center' },
    plotOptions: {
      bar: {
        columnWidth: '40px',
        borderRadius: 15,
      },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fCurrency(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    stroke: { show: false },
    yaxis: {
      labels: {
        show: false,
        formatter: (val: any) => `${val / 1000000} Mio`,
      },
    },
    xaxis: {
      show: false,
      type: 'category',
      categories: [''],
    },
  });

  return <ReactApexChart type="bar" series={props.series} options={chartOptions} height={308} />;
}
