import { useRouter } from 'next/router';
import { merge } from 'lodash-es';
import { Box } from '@mui/material';
import { ContractRelevanceFilter } from 'src/api';
import { ReactApexChart, useBaseChartOptions } from 'src/components/chart';
import { probabilities } from 'src/config';
import { PATH_APP } from 'src/paths';
import { fCurrency, fNumber } from 'src/utils/formatNumber';

export interface OfferStackedBarProps {
  categories: string[];
  series: { name: string; data: number[] }[];
  chartColors: string[];
  mode: string;
}

export function OfferStackedBar({ categories, series, chartColors, mode }: OfferStackedBarProps) {
  const router = useRouter();
  const chartOptions = merge(useBaseChartOptions(), {
    colors: chartColors,
    grid: { show: false },
    chart: {
      stacked: true,
    },
    legend: { position: 'bottom', horizontalAlign: 'center' },
    plotOptions: {
      bar: {
        columnWidth: '40px',
        borderRadius: 15,
      },
    },
    yaxis: {
      labels: {
        formatter: (val: any) => (mode == '€' ? `${val / 1000000} Mio` : fNumber(val)),
      },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (val: string) => (mode == '€' ? fCurrency(val) : fNumber(val)),
        title: {
          formatter: (val: string) => `${val}`,
        },
      },
    },
    stroke: { show: false },
    xaxis: {
      show: true,
      type: 'category',
      categories,
    },
  });

  return (
    <Box sx={{ '.apexcharts-bar-area': { cursor: 'pointer' } }}>
      <ReactApexChart
        type="bar"
        series={series}
        options={chartOptions}
        height={320}
        events={{
          dataPointSelection: (_event: any, _chartContext: any, config: any) => {
            const { name } = series[config.seriesIndex];

            if (name === 'Abgeschlossen') {
              router.push({
                pathname: PATH_APP.contracts.root,
                query: {
                  sortation: 'createdAt_asc',
                },
              });
            } else if (name === 'Abgesagt') {
              router.push({
                pathname: PATH_APP.contracts.root,
                query: {
                  sortation: 'createdAt_asc',
                  relevance: ContractRelevanceFilter.Old,
                },
              });
            } else {
              router.push({
                pathname: PATH_APP.offers.root,
                query: {
                  probability: probabilities.find((entry) => entry.label === name)?.value,
                },
              });
            }
          },
        }}
      />
    </Box>
  );
}
