import { ReactNode } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { EmptyContent } from '../EmptyContent';

interface Props {
  isNotFound?: boolean;
  title?: ReactNode;
}

export default function TableNoData({ isNotFound = true, title = 'Keine Daten' }: Props) {
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            title={title}
            sx={{
              '& span.MuiBox-root': { height: 160 },
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
