import { useState } from 'react';
import {
  Card,
  CardContent,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  ContractRelevanceFilter,
  ContractStatusFilter,
  PartnerRelevanceFilter,
  PartnerStatus,
  useDashboardPartnerAndContractsQuery,
} from 'src/api';
import Link from 'src/components/Link';
import { PATH_APP } from 'src/paths';
import { fNumber, fPercent } from 'src/utils/formatNumber';
import { DashboardParams } from './Dashboard';

const modes = ['Partner', 'Verträge'];

export function DashboardPartnerAndContracts({ seasonId, leagueId }: DashboardParams) {
  const [mode, setMode] = useState(modes[0]);

  const { data } = useDashboardPartnerAndContractsQuery({ variables: { seasonId, leagueId } });

  if (!data) return null;

  const { partner, contract } = data.dashboardPartnerAndContracts;

  return (
    <Card variant="outlined" sx={{ height: 550, overflowY: 'auto' }}>
      <ToggleButtonGroup
        fullWidth
        exclusive
        value={mode}
        onChange={(_event, value) => setMode(value)}
        size="small"
        sx={{ border: 'none' }}
      >
        {modes.map((x) => (
          <ToggleButton key={x} value={x}>
            {x}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <Divider />

      <CardContent sx={{ textAlign: 'center' }}>
        {mode == modes[0] ? (
          <Stack direction="column" alignItems="center" gap={3}>
            <div>
              <Link
                href={`${PATH_APP.partners.root}?relevance=${PartnerRelevanceFilter.Current}`}
                variant="h6"
              >
                Gesamt
              </Link>
              <Typography variant="h6">{fNumber(partner.total)}</Typography>
            </div>
            <div>
              <Link
                href={`${PATH_APP.partners.root}?relevance=${PartnerRelevanceFilter.Current}&status=${PartnerStatus.New}`}
                variant="h6"
              >
                Neukunden
              </Link>
              <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                <Typography variant="h6">{fNumber(partner.new)}</Typography>
                <Typography variant="body1" fontSize={13}>{`(${fPercent(
                  (partner.new / partner.total) * 100,
                )})`}</Typography>
              </Stack>
            </div>
            <div>
              <Link
                href={`${PATH_APP.partners.root}?relevance=${PartnerRelevanceFilter.Current}&status=${PartnerStatus.Existing}`}
                variant="h6"
              >
                Bestandskunden
              </Link>
              <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                <Typography variant="h6">{fNumber(partner.existing)}</Typography>
                <Typography variant="body1" fontSize={13}>{`(${fPercent(
                  (partner.existing / partner.total) * 100,
                )})`}</Typography>
              </Stack>
            </div>
            <div>
              <Link
                href={`${PATH_APP.partners.root}?relevance=${PartnerRelevanceFilter.Current}&status=${PartnerStatus.Lead}`}
                variant="h6"
              >
                Leads
              </Link>
              <Typography variant="h6">{fNumber(partner.leads)}</Typography>
            </div>
            <div>
              <Link
                href={`${PATH_APP.partners.root}?relevance=${PartnerRelevanceFilter.Future}`}
                variant="h6"
              >
                Fixed
              </Link>
              <Typography variant="h6">{fNumber(partner.fixed)}</Typography>
            </div>
          </Stack>
        ) : (
          <Stack direction="column" alignItems="center" gap={3}>
            <div>
              <Link
                href={`${PATH_APP.contracts.root}?relevance=${ContractRelevanceFilter.Current}`}
                variant="h6"
              >
                Gesamt
              </Link>
              <Typography variant="h6">{fNumber(contract.total)}</Typography>
            </div>
            <div>
              <Link
                href={`${PATH_APP.contracts.root}?relevance=${ContractRelevanceFilter.Current}&autoRenew=expiringOnly`}
                variant="h6"
              >
                Auslaufende Verträge
              </Link>
              <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                <Typography variant="h6">{fNumber(contract.expired)}</Typography>
                <Typography variant="body1" fontSize={13}>{`(${fPercent(
                  (contract.expired / contract.total) * 100,
                )})`}</Typography>
              </Stack>
            </div>
            <div>
              <Link
                href={`${PATH_APP.contracts.root}?relevance=${ContractRelevanceFilter.Current}&autoRenew=autoRenewOnly`}
                variant="h6"
              >
                Autom. Verlängerungen (gesamt)
              </Link>
              <Typography variant="h6">{fNumber(contract.autoRenewTotal)}</Typography>
            </div>
            <div>
              <Typography variant="h6" color="GrayText">
                Autom. Verlängerungen (offen)
              </Typography>
              <Typography variant="h6">{fNumber(contract.autoRenewOpen)}</Typography>
            </div>
            <div>
              <Link
                href={`${PATH_APP.contracts.root}?relevance=${ContractRelevanceFilter.Current}&contractStatus=${ContractStatusFilter.Canceled}`}
                variant="h6"
              >
                Kündigungen
              </Link>

              <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                <Typography variant="h6">{fNumber(contract.cancellations)}</Typography>
                <Typography variant="body1" fontSize={13}>{`(${fPercent(
                  (contract.cancellations / contract.total) * 100,
                )})`}</Typography>
              </Stack>
            </div>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
