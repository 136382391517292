import { Fragment } from 'react';
import { orderBy } from 'lodash-es';
import { Divider, SxProps } from '@mui/material';
import { InfoPopover } from './InfoPopover';

interface NotePopover {
  note?: string;
  notes?: { id: string; content: string }[];
  sx?: SxProps;
}

export function NotePopover({ note, notes, sx }: NotePopover) {
  const hasNotes = !!notes?.length;

  if (!note && !hasNotes) return null;

  const sortedNotes = orderBy(notes, 'createdAt', 'desc');

  return (
    <InfoPopover icon="eva:file-outline" sx={sx}>
      {hasNotes &&
        sortedNotes.map((note, index) => (
          <Fragment key={note.id}>
            {note.content}
            {index + 1 !== notes.length && <Divider sx={{ my: 1 }} />}
          </Fragment>
        ))}
      {note && (
        <>
          {hasNotes && <Divider sx={{ my: 1 }} />}
          {note}
        </>
      )}
    </InfoPopover>
  );
}
