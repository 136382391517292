import { useEffect } from 'react';
import { Alert, MenuItem, TextField } from '@mui/material';
import { Season, useMyAccountQuery, useSeasonsQuery } from 'src/api';

interface SeasonsSelectProps {
  value?: string;
  onChange?: (season: Season) => void;
  label?: string;
}

export default function SeasonsSelectSmall({ value = '', onChange, label }: SeasonsSelectProps) {
  const { error, data } = useSeasonsQuery();
  const { data: myAccountData } = useMyAccountQuery();
  const seasons = [...(data?.seasons.edges ?? [])].map((edge) => edge.node).reverse();

  const findSeason = (seasonId?: string) => seasons.find((season) => season.id === seasonId) as Season;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange?.(findSeason(event.target.value));

  useEffect(() => {
    if (seasons.length && myAccountData)
      onChange?.(findSeason(myAccountData.myAccount?.defaultSeason) ?? seasons[0]);
  }, [seasons?.length, myAccountData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Alert severity="error">Saisons konnten nicht geladen werden</Alert>;

  return (
    <TextField
      fullWidth
      size="small"
      select
      label={label || 'Saison'}
      value={value}
      onChange={handleChange}
      SelectProps={{
        MenuProps: {
          sx: { '& .MuiPaper-root': { maxHeight: 260 } },
        },
      }}
      sx={{
        maxWidth: 240,
        minWidth: 'auto',
        textTransform: 'capitalize',
      }}
    >
      {seasons.map((season) => (
        <MenuItem
          key={season.id}
          value={season.id}
          sx={{
            mx: 1,
            my: 0.5,
            borderRadius: 0.75,
            typography: 'body2',
            textTransform: 'capitalize',
          }}
        >
          {season.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
