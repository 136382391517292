import { MouseEvent, ReactNode, useState } from 'react';
import { SxProps } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { AutoLink } from '../AutoLink';
import Iconify from '../Iconify';

interface InfoPopoverProps {
  icon?: string;
  sx?: SxProps;
  children?: ReactNode;
}

export function InfoPopover({ icon = 'eva:info-outline', sx, children }: InfoPopoverProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  return (
    <>
      <Iconify
        icon={icon}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        width={16}
        height={16}
        color="grey.600"
        sx={sx}
      />
      <Popover
        disableRestoreFocus
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        sx={{ pointerEvents: 'none' }}
      >
        <Typography fontSize={14} sx={{ p: '12px' }} maxWidth={480} whiteSpace="break-spaces">
          <AutoLink>{children}</AutoLink>
        </Typography>
      </Popover>
    </>
  );
}
