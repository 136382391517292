import { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { League, Season } from 'src/api';
import LeaguesSelectSmall from 'src/components/app/LeaguesSelectSmall';
import SeasonsSelectSmall from 'src/components/app/SeasonsSelectSmall';
import { DashboardDiscount } from './DashboardDiscount';
import { DashboardForecast } from './DashboardForecast';
import { DashboardInventory } from './DashboardInventory';
import { DashboardKeyRight } from './DashboardKeyRight';
import { DashboardOffer } from './DashboardOffer';
import { DashboardPartnerAndContracts } from './DashboardPartner';

export interface DashboardParams {
  seasonId: string;
  leagueId: string;
}

export function Dashboard() {
  const [leagueId, setLeagueId] = useState('');
  const [seasonId, setSeasonId] = useState('');

  return (
    <>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12}>
          <Stack direction="row">
            <Stack direction="row" sx={{ marginLeft: 'auto' }} gap={3}>
              <SeasonsSelectSmall value={seasonId} onChange={(season: Season) => setSeasonId(season.id)} />
              <LeaguesSelectSmall value={leagueId} onChange={(league: League) => setLeagueId(league.id)} />
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      {leagueId && seasonId && (
        <>
          <Grid container spacing={3} paddingTop={3} alignItems="stretch">
            <Grid item xs={12} md={4}>
              <DashboardForecast seasonId={seasonId} leagueId={leagueId} />
            </Grid>
            <Grid item xs={12} md={2}>
              <DashboardDiscount seasonId={seasonId} leagueId={leagueId} />
            </Grid>
            <Grid item xs={12} md={4}>
              <DashboardInventory seasonId={seasonId} leagueId={leagueId} />
            </Grid>
            <Grid item xs={12} md={2}>
              <DashboardPartnerAndContracts seasonId={seasonId} leagueId={leagueId} />
            </Grid>
          </Grid>

          <Grid container spacing={3} paddingTop={3} alignItems="stretch">
            <Grid item xs={12} md={4}>
              <DashboardOffer seasonId={seasonId} leagueId={leagueId} />
            </Grid>

            <Grid item xs={12} md={8}>
              <DashboardKeyRight seasonId={seasonId} leagueId={leagueId} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
