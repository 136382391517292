import { useCallback, useEffect, useState } from 'react';
import { get, sortBy } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { DropResult } from 'react-beautiful-dnd';

type UseOrderedDataReturnType<Item> = [
  data: Item[],
  updateOrder: (result: DropResult) => void,
  isLoading: boolean,
];

// to prevent flickering we use a local copy of the api data with the new order
// until we get new api data with the new order
export function useOrderedData<Item>(
  apiData: Item[],
  idPath: string,
  mutationId: string,
  mutation: any,
  sortKey = 'order',
): UseOrderedDataReturnType<Item> {
  const { enqueueSnackbar } = useSnackbar();
  const [sortedData, setSortedData] = useState<Item[]>(() => sortBy(apiData, 'order'));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) return;
    const sortedApiData = sortBy(apiData, sortKey);
    setSortedData(sortedApiData);
  }, [apiData, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateOrder = useCallback(
    ({ destination, source, draggableId }: DropResult) => {
      if (!destination) return;
      setIsLoading(true);
      mutation({
        variables: {
          [mutationId]: draggableId,
          input: { [sortKey]: destination.index },
        },
      }).then(() => {
        setIsLoading(false);
        enqueueSnackbar('Neue Ordnung gespeichert');
      });

      // create a temp copy of the data in the new order
      const dataCopy = [...sortedData];

      const draggedGroup = sortedData.find((item) => get(item, idPath) === draggableId) as Item;

      dataCopy.splice(source.index, 1);
      dataCopy.splice(destination.index, 0, draggedGroup);

      setSortedData(dataCopy);
    },
    [sortedData, idPath, mutationId, mutation, sortKey, enqueueSnackbar],
  );

  return [sortedData, updateOrder, isLoading];
}
