import { useEffect } from 'react';
import { Alert, MenuItem, TextField } from '@mui/material';
import { League, useLeaguesQuery, useMyAccountQuery } from 'src/api';

interface LeaguesSelectProps {
  value?: string;
  onChange?: (league: League) => void;
  label?: string;
}

export default function LeaguesSelectSmall({ value = '', onChange, label }: LeaguesSelectProps) {
  const { error, data } = useLeaguesQuery();
  const { data: myAccountData } = useMyAccountQuery();
  const leagues = [...(data?.leagues.edges ?? [])].map((edge) => edge.node).reverse();

  const findLeague = (leagueId?: string) => leagues.find((league) => league.id === leagueId) as League;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange?.(findLeague(event.target.value));

  useEffect(() => {
    if (leagues.length && myAccountData)
      onChange?.(findLeague(myAccountData.myAccount?.defaultLeague) ?? leagues[0]);
  }, [leagues?.length, myAccountData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <Alert severity="error">Could not load Leagues</Alert>;

  if (!leagues?.length) return null;

  return (
    <TextField
      fullWidth
      select
      size="small"
      label={label || 'Liga'}
      value={value}
      onChange={handleChange}
      SelectProps={{
        MenuProps: {
          sx: { '& .MuiPaper-root': { maxHeight: 260 } },
        },
      }}
      sx={{
        maxWidth: 240,
        minWidth: 'auto',
        textTransform: 'capitalize',
      }}
    >
      {leagues.map((league) => (
        <MenuItem
          key={league.id}
          value={league.id}
          sx={{
            mx: 1,
            my: 0.5,
            borderRadius: 0.75,
            typography: 'body2',
            textTransform: 'capitalize',
          }}
        >
          {league.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
