import { isNumber } from 'lodash-es';
import numeral from 'numeral';

// ----------------------------------------------------------------------

if (numeral.locale() !== 'de') {
  numeral.register('locale', 'de', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: () => '.',
    currency: {
      symbol: '€',
    },
  });

  numeral.locale('de');
}

// ----------------------------------------------------------------------

const fallback = '–';

//  NOTE: numeral returns `NaN` for very small numbers (< 0.0000001)
function customNumeral(value: any): numeral.Numeral {
  // check if the number is so small it'd trigger NaN
  if (value > 0 && value < 1e-6) return numeral(0);
  if (value < 0 && value > -1e-6) return numeral(0);

  return numeral(value);
}

export function fCurrency(number?: string | number) {
  return fCurrencyLong(number).replace(',00', '');
}

export function fCurrencyLong(number?: string | number) {
  return isNumber(number)
    ? customNumeral(number)
        .format('0,0.00 $')
        .replace(' ', '\u00A0') // add non-breaking space
        .replace('-', '\u2011') // add non-breaking hyphen
    : fallback;
}

export function fMoney(number?: string | number) {
  if (!isNumber(number)) return fallback;
  return customNumeral(number).format('0,0.00').replace(',00', '');
}

export function fPercent(number?: number) {
  return isNumber(number)
    ? customNumeral(number / 100)
        .format('0,0.0 %')
        .replace(',0', '')
        .replace(' ', '\u00A0')
    : fallback;
}

export function fNumber(number?: string | number) {
  if (!isNumber(number)) return fallback;

  const formatted = customNumeral(number).format('0,0.00').replace(',00', '');
  return formatted.match(/,\d0$/i) ? formatted.slice(0, formatted.length - 1) : formatted;
}

export function fShortenNumber(number?: string | number) {
  return isNumber(number) ? customNumeral(number).format('0.00a').replace(',00', '') : fallback;
}

export function fBytes(number?: string | number) {
  return isNumber(number) ? customNumeral(number).format('0,0.00 b').replace(' ', '\u00A0') : fallback;
}
